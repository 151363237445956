import {getReadBucket, repoURL} from '@Libraries/s3-library';
import {getUnixTimestamp} from '@Utils/date.util';
import {Deferred} from '@Libraries/deferred';
import {getCacheBustedURL} from '@Utils/url.util';
import type {EmbeddedEditorCallback} from '@Libraries/embedded-editor';
import type {CreateDesignFromUploadOpts} from '@Components/design-selector/design-selector.types';
import type {GalleryPageURI} from '@Components/design-gallery/design-gallery.types';
import {GalleryThemeURI} from '@Components/design-gallery/design-gallery.types';
import type {AiTextMorphData, PosterLoadObject} from '@PosterWhiteboard/poster/poster.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import {morphTextItemsForAiPrompt} from '@Libraries/ai-text-design-morph.library';

export const PREVIEW_BUCKET = 'posterpreviews';

export enum PosterPreviewExtension {
  IMAGE = 'jpg',
  VIDEO = 'mp4',
}

export enum PosterPreviewSize {
  THUMB = 'THUMB',
  SCREEN = 'SCREEN',
}

/**
 * Creates a URL to a resource in the S3 repository.
 */
export const getPreviewURL = (hashedId: string, extension: string, size: PosterPreviewSize = PosterPreviewSize.THUMB): string => {
  const cacheBustTs = getUnixTimestamp(new Date());
  let sizeSuffix = '';

  if (size) {
    sizeSuffix = '_screen';
  }

  return repoURL(`${PREVIEW_BUCKET}/${hashedId}${sizeSuffix}.${extension}?ts=${cacheBustTs}`, getReadBucket());
};

export const loadDesignSelector = (
  designsLoadedCallback: () => void,
  onDesignSelected: EmbeddedEditorCallback,
  divToLoadIn: string,
  loadPostersIfNotLoadedCustomEvent: string,
  startDesignFromUploadBtnOpts?: CreateDesignFromUploadOpts
): void => {
  window.PMW.util.require(window.PMW.loadDesignSelectorElement === undefined, 'designSelector', true, () => {
    window.PMW.loadDesignSelectorElement(designsLoadedCallback, onDesignSelected, divToLoadIn, loadPostersIfNotLoadedCustomEvent, startDesignFromUploadBtnOpts);
  });
};

export const loadDesignGallery = async (
  contentElementSelector: string,
  contentContainerSelector: string,
  disableCreateFromScratchCTA: boolean,
  startingPageURI: GalleryPageURI = GalleryThemeURI.DEFAULT
): Promise<unknown> => {
  const deferred = new Deferred();

  window.PMW.util.require(window.PMW.initGalleryContentInElement === undefined, 'galleryDialog', true, () => {
    window.PMW.initGalleryContentInElement(true, contentElementSelector, contentContainerSelector, disableCreateFromScratchCTA, startingPageURI);
    deferred.resolve();
  });

  return deferred.promise;
};

export const generatePosterPreview = async (posterHashedId: string): Promise<void> => {
  return (await window.PMW.writeLocal('posterbuilder/generatePreview', {
    posterHashId: posterHashedId,
  })) as Promise<void>;
};

const resolvePromiseIfNonPreviewAssetFound = (ajax: XMLHttpRequest, intervalHandler: NodeJS.Timeout, deferred: Deferred): void => {
  const isPlaceholder = parseInt(ajax.getResponseHeader('x-amz-meta-is-placeholder') ?? '0', 10);
  if (!isPlaceholder) {
    clearInterval(intervalHandler);
    deferred.resolve();
  }
};

const resolvePromiseIfUpdatedAssetFound = (ajax: XMLHttpRequest, referenceTime: number, intervalHandler: NodeJS.Timeout, deferred: Deferred): void => {
  if (typeof ajax.getResponseHeader('last-modified') !== 'undefined') {
    const newLastModified = ajax.getResponseHeader('last-modified');
    const isPlaceholder = parseInt(ajax.getResponseHeader('x-amz-meta-is-placeholder') ?? '0', 10);

    if (newLastModified) {
      const lastModifiedTime = Date.parse(newLastModified);

      if (lastModifiedTime > referenceTime && !isPlaceholder) {
        clearInterval(intervalHandler);
        deferred.resolve();
      }
    }
  }
};

const checkForUpdatesInURL = (intervalHandler: NodeJS.Timeout, deferred: Deferred, urlObj: URL, ignoreUpdates: boolean, referenceTime: number): void => {
  const ajax = new XMLHttpRequest();
  ajax.open('HEAD', `${getCacheBustedURL(urlObj.href)}`, true);
  ajax.onerror = (): void => {
    console.log('error!');
  };

  // eslint-disable-next-line func-names
  ajax.onreadystatechange = function (): void {
    if (this.readyState === this.DONE) {
      if (ignoreUpdates) {
        resolvePromiseIfNonPreviewAssetFound(ajax, intervalHandler, deferred);
      } else {
        resolvePromiseIfUpdatedAssetFound(ajax, referenceTime, intervalHandler, deferred);
      }
    }
  };

  ajax.onerror = (): void => {
    clearInterval(intervalHandler);
    deferred.reject();
  };

  ajax.send();
};

export const pollForUpdatesInURL = (url: string, ignoreUpdates = false): Deferred => {
  const referenceTime = new Date().getTime();
  let iteration = 0;
  const MAX_ITERATIONS = 300;
  const ITERATION_TIMEOUT_MS = 2000;
  const deferred = new Deferred();

  if (!url) {
    deferred.reject();
    return deferred;
  }
  const urlObj = new URL(url);

  const intervalHandler = setInterval((): void => {
    if (iteration >= MAX_ITERATIONS) {
      clearInterval(intervalHandler);
      deferred.resolve();
    }

    checkForUpdatesInURL(intervalHandler, deferred, urlObj, ignoreUpdates, referenceTime);
    iteration += 1;
  }, ITERATION_TIMEOUT_MS);

  checkForUpdatesInURL(intervalHandler, deferred, urlObj, ignoreUpdates, referenceTime);

  return deferred;
};

export const pollForPosterPreviewChanges = (designHashedId: string, isVideo = false, ignoreGraphicUpdates = false): Deferred => {
  const url = getPosterPreviewURL(designHashedId, isVideo);

  return pollForUpdatesInURL(url, ignoreGraphicUpdates);
};

export const getPosterPlaceholderImagePreviewURL = (): string => {
  return window.PMW.util.asset_url('images/landing-page/placeholder-image.png');
};
export const getPosterPlaceholderVideoPreviewURL = (): string => {
  return window.PMW.util.asset_url('images/landing-page/placeholder-video.mp4');
};

export const getPosterPreviewURL = (designHashedId: string, isVideo = false, size = PosterPreviewSize.SCREEN): string => {
  const extension = isVideo ? PosterPreviewExtension.VIDEO : PosterPreviewExtension.IMAGE;

  return getPreviewURL(designHashedId, extension, size);
};

export const handleAiTextMorphForPoster = async (posterObject: PosterLoadObject, aiTextMorphData: AiTextMorphData): Promise<PosterLoadObject> => {
  const {prompt} = aiTextMorphData;

  const pageHashid = posterObject.pages.pageOrder[0];
  const {items} = posterObject.pages.pagesHashMap[pageHashid];
  const mapping: Record<string, string> = {};

  items.itemsOrder.forEach((itemId): void => {
    if (items.itemsHashMap[itemId].gitype === ITEM_TYPE.TEXT || items.itemsHashMap[itemId].gitype === ITEM_TYPE.FANCY_TEXT) {
      mapping[itemId] = (items.itemsHashMap[itemId] as TextItemObject).text;
    }
  });

  if (Object.keys(mapping).length > 0) {
    const morphResponse = await morphTextItemsForAiPrompt(mapping, prompt);
    for (const graphicItemKey of Object.keys(morphResponse.textContentChanges)) {
      if (morphResponse.textContentChanges[graphicItemKey].text) {
        items.itemsHashMap[graphicItemKey] = {...items.itemsHashMap[graphicItemKey], ...morphResponse.textContentChanges[graphicItemKey]};
      }
    }
  }

  return posterObject;
};
